import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';

export class FormPrestataire extends Component {
    constructor() {
        super();
        this.state = {
            listeprest: [],
            listeprestParent: [],

            liste_qualitatif: [],
            liste_quantitatif: [],

            listesite: [],
            liste_banque: [],
            selectedPrest: null,
            debPrest: new Date(),
            idPrest: 0,
            bonusPrest: 0,
            idSite: null,
            nomPrest: '',
            typePrest: null,
            switfBanquePrest: '',
            banquePrest: '',
            nocpteBanquePrest: '',
            nomcpteBanquePrest: '',
            liste_type: [],
            telPrest: '',
            emailPrest: '',
            photoPrest: 'photo.png',
            professionPrest: '',
            niveauEtudPrest: null,
            idPrestParent: null,
            idBailleurPrest: 1,
            liste_param_eval: [],
            liste_param_eval_adding: [],
            liste_niveau: [
                { label: 'CEP', value: 'CEP' },
                { label: 'BEPC', value: 'BEPC' },
                { label: 'BAC', value: 'BAC' },
                { label: 'Université', value: 'Université' },
                { label: 'Non applicable', value: 'Non applicable' }
            ],
            sexePrest: null,
            liste_sexe: [
                { label: 'Masculin', value: 'Masculin' },
                { label: 'Féminin', value: 'Féminin' },
                { label: 'Non applicable', value: 'Non applicable' }
            ],
            dateNaissPrest: new Date(),
            lieuNaissPrest: '',
            nopiPrest: '',
            typepiPrest: null,
            liste_piece: [
                { label: "CNI", value: 'CNI' },
                { label: "Carte électeur", value: 'Carte électeur' },
                { label: "Carte scolaire", value: 'Carte scolaire' },
                { label: 'Passport', value: 'Passport' },
                { label: 'Non applicable', value: 'Non applicable' }
            ],
            categoriePrest: null,
            liste_categorie: [],
            liste_bailleur: [],
            selectedParamEval: null,
            modif_param_eval: false,
            maxPeval: 0,
            puPeval: 0,
            file: null,
            msg: '',
            modif: false,
            visible: false,
            visible_dlg_form: false,
            visible_modif_param: false,
            showindicator: false,
            minDate: moment("2021-01-01")
        };
        this.edit_prestataire = this.edit_prestataire.bind(this);
        this.conf_delete_prestataire = this.conf_delete_prestataire.bind(this);

        this.delete_ligne_param = this.delete_ligne_param.bind(this);
        this.conf_delete_param_eval = this.conf_delete_param_eval.bind(this);
        this.inputNumberEditorQual = this.inputNumberEditorQual.bind(this);
        this.inputNumberEditorQuant = this.inputNumberEditorQuant.bind(this);
        this.edit_param_eval = this.edit_param_eval.bind(this);

        this.puPevalEditor = this.puPevalEditor.bind(this);
        this.maxPevalEditor = this.maxPevalEditor.bind(this);

        this.FbrService = new FbrService();
        this.toogleDlg = this.toogleDlg.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);

    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    getParent(id, data) {
        let nom = '';
        if (data !== null && data !== undefined) {
            let liste = data.filter(function (el) {
                return el.idPrest == id;
            });
            if (liste !== null && liste !== undefined) {
                if (liste.length > 0) {
                    nom = liste[0].nomPrest;
                }
            }
        }
        return nom;
    }

    inputNumberEditorQual(props, field) {
        return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQual(props, e.value)} />;
    }
    inputNumberEditorQuant(props, field) {
        return <InputNumber value={props.rowData[field]} onChange={(e) => this.onEditorValueChangeQuant(props, e.value)} />;
    }

    onEditorValueChangeQual(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_qualitatif: updateds });
    }

    onEditorValueChangeQuant(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_quantitatif: updateds });
    }


    puPevalEditor(props) {
        return this.inputNumberEditorQuant(props, 'puPeval');
    }

    maxPevalEditor(props) {
        return this.inputNumberEditorQual(props, 'maxPeval');
    }

    edit_param_eval(data) {
        if (data != null && data != undefined) {
            this.setState({ puPeval: data.puPeval });
            this.setState({ maxPeval: data.maxPeval });
            this.setState({ selectedParamEval: data });
            this.setState({ modif_param_eval: true });
        }
    }

    annule_param_eval() {
        this.setState({ puPeval: 0 });
        this.setState({ maxPeval: 0 });
        this.setState({ modif_param_eval: false });
        this.forceUpdate();
    }

    get_liste_codif() {
        // this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('BANQUE').then(data => {
            // this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_banque: liste });
            }
        });

        this.FbrService.get_liste_codif('CATEGORIE_PRESTATAIRE').then(data => {
            // this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_categorie: liste });
            }
        });


        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_type: liste });
            }
        });

        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('BAILLEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_bailleur: liste });
            }
        });

    }

    get_liste_site() {
        // this.setState({ showindicator: true });
        this.FbrService.get_liste_site().then(data => {
            // this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idSite, label: x.nomSite }
                });
                this.setState({ listesite: liste });
            }
        });


    }

    fileUpload(file, namefile) {
        this.FbrService.upload_photo_prestataire(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement effectué avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec chargement' });
            }
        });
    }

    componentDidMount() {

        this.get_liste_site();
        this.get_liste_codif();

        this.get_liste_prestataire();
    }

    delete_ligne_param(data) {
        for (var i = this.state.liste_param_eval_adding.length; i--;) {
            if (data.idType.libType == this.state.liste_param_eval_adding[i].idType.libType) {
                this.state.liste_param_eval_adding.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    conf_delete_param_eval(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedParamEval: data });
            this.setState({ msg: data.idType.libType });
            this.setState({ visible_param_eval: true });
        }
    }


    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire().then(data => this.setState({ showindicator: false }, () => {
            if (data !== null && data !== undefined) {
                let listeprest = data;
                for (var i = listeprest.length; i--;) {
                    listeprest[i].nomPrestParent = this.getParent(listeprest[i].idPrestParent, data);
                }
                this.setState({ listeprest: listeprest });

                let liste = data.filter(function (el) {
                    return el.typePrest.libCodif !== 'PERSONNE';
                });
                if (liste !== null && liste !== undefined) {
                    let listeprestParent = liste.map(x => {
                        return { value: x.idPrest, label: x.nomPrest }
                    });
                    this.setState({ listeprestParent: listeprestParent });
                }
            }
        }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_prestataire();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    resultat_param_eval(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_param_eval();
            this.get_liste_param_eval_forAding(this.state.idPrest, this.state.typePrest, this.state.idBailleurPrest);
            this.get_liste_param_eval();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    get_liste_param_eval_forAding(idPrest, typePrest, idBailleur) {
        if (idPrest !== null && typePrest !== null && idBailleur !== null) {
            this.setState({ showindicator: true });
            this.FbrService.get_liste_param_eval_forAding(idPrest, typePrest, idBailleur).then(data => {
                this.setState({ showindicator: false, liste_qualitatif: [], liste_quantitatif: [] });
                if (data !== null && data !== undefined) {
                    if (data.length > 0) {
                        let liste_qual = data.filter(function (el) {
                            return el.idType.categorieType === 'Qualitative';
                        });
                        let liste_quant = data.filter(function (el) {
                            return el.idType.categorieType === 'Quantitative';
                        });
                        this.setState({ liste_qualitatif: liste_qual });
                        this.setState({ liste_quantitatif: liste_quant });
                    }
                }
                //this.setState({ liste_param_eval_adding: data, showindicator: false });
            });
        }
    }

    get_liste_param_eval() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_param_eval(this.state.idPrest, 'Tous').then(data => this.setState({ liste_param_eval: data, showindicator: false }));
    }

    edit_prestataire(data) {
        if (data != null && data != undefined) {
            this.setState({ nomPrest: data.nomPrest });
            this.setState({ typePrest: data.typePrest.idCodif, idPrest: data.idPrest }, () => {
                this.get_liste_param_eval_forAding(data.idPrest, data.typePrest.idCodif, data.idBailleur.idCodif);
                this.get_liste_param_eval();
            });

            this.setState({ idBailleurPrest: data.idBailleur.idCodif });
            this.setState({ categoriePrest: data.categoriePrest.idCodif });
            this.setState({ telPrest: data.telPrest });
            this.setState({ emailPrest: data.emailPrest });
            this.setState({ photoPrest: data.photoPrest });
            this.setState({ professionPrest: data.professionPrest });
            this.setState({ niveauEtudPrest: data.niveauEtudPrest });
            this.setState({ sexePrest: data.sexePrest });
            this.setState({ dateNaissPrest: data.dateNaissPrest });
            this.setState({ lieuNaissPrest: data.lieuNaissPrest });
            this.setState({ nopiPrest: data.nopiPrest });
            this.setState({ typepiPrest: data.typepiPrest });
            this.setState({ debPrest: new Date(moment(data.debPrest, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ idSite: data.idSite.idSite });
            this.setState({ idPrestParent: data.idPrestParent });
            this.setState({
                nocpteBanquePrest: data.nocpteBanquePrest,
                nomcpteBanquePrest: data.nomcpteBanquePrest
            });
            this.setState({ switfBanquePrest: data.switfBanquePrest });
            if (data.banquePrest !== null && data.banquePrest !== undefined) {
                this.setState({ banquePrest: data.banquePrest.idCodif });
            } else {
                this.setState({ banquePrest: '' });
            }
            this.setState({ modif: true });
            this.setState({ selectedPrest: data });
            this.setState({ visible_dlg_form: true, bonusPrest: data.bonusPrest });
        }
    }

    annule_prestataire() {
        this.setState({ nomPrest: '' });
        this.setState({ nocpteBanquePrest: '', nomcpteBanquePrest: '' });
        this.setState({ switfBanquePrest: '' });

        this.setState({ telPrest: '' });
        this.setState({ emailPrest: '' });
        this.setState({ photoPrest: '' });
        this.setState({ professionPrest: '' });
        this.setState({ niveauEtudPrest: '' });
        this.setState({ sexePrest: '' });
        this.setState({ dateNaissPrest: '' });
        this.setState({ lieuNaissPrest: '' });
        this.setState({ nopiPrest: '' });
        this.setState({ typepiPrest: '' });
        this.setState({ idPrest: 0, bonusPrest: 0 });
        if (this.state.modif == true) {
            this.setState({ visible_dlg_form: false });
        }
        this.setState({ modif: false });
        this.setState({ debPrest: null });
        this.setState({ idPrestParent: '' });
        this.setState({ banquePrest: '' });
        this.setState({ typePrest: '' });
        this.setState({ liste_param_eval: [] })
        this.forceUpdate();
    }

    enreg_param_eval_batch(idPrest) {
        if (this.state.liste_param_eval_adding == null || this.state.liste_param_eval_adding == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Aucun indicateur à affecter' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            if (this.state.liste_qualitatif.length > 0) {
                for (var i = this.state.liste_qualitatif.length; i--;) {
                    this.state.liste_qualitatif[i].iduser = userconnected.idUser;
                    this.state.liste_qualitatif[i].idPrest = { idPrest: idPrest };
                }
            }
            if (this.state.liste_quantitatif.length > 0) {
                for (var i = this.state.liste_quantitatif.length; i--;) {
                    this.state.liste_quantitatif[i].iduser = userconnected.idUser;
                    this.state.liste_quantitatif[i].idPrest = { idPrest: idPrest };
                }
            }

            let liste_param_eval_adding = this.state.liste_quantitatif.concat(this.state.liste_qualitatif);

            this.FbrService.enreg_param_eval_batch(liste_param_eval_adding).then(data => {
                if (data.code == 200) {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Affectations réussies' });
                    this.setState({ liste_param_eval_adding: [], liste_quantitatif: [], liste_qualitatif: [], liste_param_eval: [] })
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: data.contenu });
                }
                this.setState({ showindicator: false });
            });
            this.forceUpdate();
        }
    }


    enreg_prestataire() {
        if (this.state.nomPrest == "" || this.state.typePrest == "" || this.state.idSite == "" || this.state.debPrest == null || this.state.debPrest == "" || this.state.idBailleurPrest == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.bonusPrest == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez saisir l'enveloppe des bonus " });
        } else {
            if (moment(this.state.debPrest, 'DD/MM/YYYY') < moment('01/01/2021', 'DD/MM/YYYY')) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'La date de début est incorrecte, veuillez saisir une date après le 01/01/2021' });
                return;
            }
            //let dateNaiss_Prest = moment(this.state.dateNaissPrest, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let upload = false;
            let nomfic = this.state.photoPrest;
            if (this.state.file != null && this.state.file != undefined && this.state.file != '') {
                let name = this.state.file.name;
                upload = true;
                nomfic = this.state.nomPrest + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let prestataire = {
                    idPrest: 0,
                    nomPrest: this.state.nomPrest,
                    typePrest: { idCodif: this.state.typePrest },
                    categoriePrest: { idCodif: this.state.categoriePrest },
                    telPrest: this.state.telPrest,
                    emailPrest: this.state.emailPrest,
                    photoPrest: nomfic,
                    professionPrest: this.state.professionPrest,
                    niveauEtudPrest: this.state.niveauEtudPrest,
                    sexePrest: this.state.sexePrest,
                    debPrest: moment(this.state.debPrest, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    nopiPrest: this.state.nopiPrest,
                    typepiPrest: this.state.typepiPrest,
                    nocpteBanquePrest: this.state.nocpteBanquePrest,
                    swiftPrest: this.state.swiftPrest,
                    idPrestParent: this.state.idPrestParent,
                    idBailleur: { idCodif: this.state.idBailleurPrest },
                    idSite: { idSite: this.state.idSite },
                    bonusPrest: this.state.bonusPrest,
                    banquePrest: { idCodif: this.state.banquePrest },
                    nomcpteBanquePrest: this.state.nomcpteBanquePrest,
                }

                this.FbrService.enreg_prestataire(prestataire).then(data => {
                    if (data != undefined && data != null) {
                        /* if (upload === true) {
                            this.fileUpload(this.state.file, nomfic);
                        } */

                        this.resultat(data.code, data.contenu);
                        if (data.code == 200) {
                            this.enreg_param_eval_batch(data.id_ref);
                        }
                    }

                });
            } else {
                let prestataire = this.state.selectedPrest;
                prestataire.nomPrest = this.state.nomPrest;
                prestataire.typePrest = { idCodif: this.state.typePrest };
                prestataire.categoriePrest = { idCodif: this.state.categoriePrest };
                prestataire.debPrest = moment(this.state.debPrest, 'DD/MM/YYYY').format('DD/MM/YYYY');
                prestataire.telPrest = this.state.telPrest;
                prestataire.emailPrest = this.state.emailPrest;
                prestataire.photoPrest = nomfic;
                prestataire.idPrestParent = this.state.idPrestParent;
                prestataire.sexePrest = this.state.sexePrest;
                prestataire.nocpteBanquePrest = this.state.nocpteBanquePrest;
                prestataire.nopiPrest = this.state.nopiPrest;
                prestataire.typepiPrest = this.state.typepiPrest;
                prestataire.idSite = { idSite: this.state.idSite };
                prestataire.swiftPrest = this.state.swiftPrest;
                prestataire.bonusPrest = this.state.bonusPrest;
                prestataire.banquePrest = { idCodif: this.state.banquePrest };
                prestataire.nomcpteBanquePrest = this.state.nomcpteBanquePrest;
                this.FbrService.enreg_prestataire(prestataire).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                        if (data.code == 200) {
                            this.enreg_param_eval_batch(data.id_ref);
                        }
                    }
                });
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_prestataire(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_prestataire(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_prestataire() {
        if (this.state.selectedPrest == null || this.state.selectedPrest == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_prestataire(this.state.selectedPrest).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_prestataire(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPrest: data });
            this.setState({ msg: data.nomPrest });
            this.setState({ visible: true });
        }
    }

    enreg_param_eval() {
        let param = this.state.selectedParamEval;
        param.maxPeval = this.state.maxPeval;
        param.puPeval = this.state.puPeval;
        this.FbrService.enreg_param_eval(param).then(data => this.resultat_param_eval(data.code, data.contenu));
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }

    toogleDlg() {
        this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
    }

    leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nouveau" icon="pi pi-plus" className="p-button-success mr-2" style={{ width: 120 }} onClick={this.toogleDlg} />
                <Button label="Rafraichir" icon="pi pi-refresh" style={{ width: 120 }} className="p-button-info" onClick={(e) => this.get_liste_prestataire()} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} />}
            </React.Fragment>
        )
    }

    actionTemplate_ligne(rowData) {
        return <Button onClick={() => this.delete_ligne_param(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" />
    }

    actionTemplatePeval(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_param_eval(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_param_eval(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_prestataire();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid card" style={{ minHeight: 1000 }}>
                <Toast ref={(el) => this.toast = el} />
                <Toolbar style={{ paddingBlock: 5 }} className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Modification" visible={this.state.modif_param_eval} style={{ width: '350px' }} modal={true} onHide={() => this.setState({ modif_param_eval: false })}>
                    {this.state.selectedParamEval !== null && this.state.selectedParamEval !== undefined ?
                        <div className="card">
                            <div className="grid">
                                <div className="col-12">
                                    {this.state.selectedParamEval.idType.categorieType == 'Quantitative' ?
                                        <label >Prix *</label>
                                        :
                                        <label >Note max. *</label>
                                    }
                                    {this.state.selectedParamEval.idType.categorieType == 'Quantitative' ?
                                        <InputNumber value={this.state.puPeval} onChange={(e) => this.setState({ puPeval: e.value })} />
                                        :
                                        <InputNumber value={this.state.maxPeval} onChange={(e) => this.setState({ maxPeval: e.value })} />
                                    }
                                </div>
                            </div>
                            <center>
                                <div className="grid" style={{ width: 280 }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                        this.setState({ modif_param_eval: false });
                                    }} style={{ width: 110 }} /></div>
                                    <div className="col"><Button label="Terminer" icon="pi pi-check" onClick={() => this.enreg_param_eval()} style={{ width: 110, marginLeft: -20 }} /></div>
                                </div>
                                <br />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>
                        </div>
                        : null}
                </Dialog>

                <Dialog maximizable visible={this.state.visible_dlg_form} style={{ width: '95%' }} header={this.state.modif == true ? "Modification" : "Nouveau acteur"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                    footer={
                        <div style={{ backgroundColor: '#f7f7f7', padding: 10 }}>
                            <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.setState({ visible_dlg_form: false })} />
                            <Button label="Terminer" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_prestataire()} style={{ width: 120, marginLeft: 10 }} />
                        </div>
                    }>
                    <div className="card" style={{ marginBottom: -20 }}>
                        <div className="grid">

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Type *</label>
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.typePrest} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_type} onChange={(e) => {
                                    this.setState({ typePrest: e.value }, () => {
                                        this.get_liste_param_eval_forAding(this.state.idPrest, this.state.typePrest, this.state.idBailleurPrest);
                                    });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Nom *</label>
                                <InputText value={this.state.nomPrest} onChange={(e) => this.setState({ nomPrest: e.target.value })} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="site">Site *</label>
                                <Dropdown id='site' placeholder="Sélectionner" autoWidth={false} value={this.state.idSite} filter={true} filterBy='label,value' filterMatchMode='contains' options={this.state.listesite} onChange={(e) => {
                                    this.setState({ idSite: e.value });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Début * </label>
                                <Calendar dateFormat="dd/mm/yy" value={this.state.debPrest} onChange={(e) => this.setState({ debPrest: e.target.value })} viewDate={new Date()} appendTo={document.body} yearNavigator monthNavigator yearRange='2019:2070' />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Enveloppe bonus *</label>
                                <InputNumber value={this.state.bonusPrest} maxFractionDigits={2} onChange={(e) => this.setState({ bonusPrest: e.value })} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Téléphone </label>
                                <InputText value={this.state.telPrest} onChange={(e) => this.setState({ telPrest: e.target.value })} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Catégorie * </label>
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.categoriePrest} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_categorie} onChange={(e) => {
                                    this.setState({ categoriePrest: e.value });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Structure*</label>
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.idPrestParent} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.listeprestParent} onChange={(e) => {
                                    this.setState({ idPrestParent: e.value });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Bailleur * </label>
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.idBailleurPrest} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_bailleur} onChange={(e) => {
                                    this.setState({ idBailleurPrest: e.value }, () => {
                                        this.get_liste_param_eval_forAding(this.state.idPrest, this.state.typePrest, this.state.idBailleurPrest);
                                    });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Banque *</label>
                                <Dropdown placeholder="Sélectionner" autoWidth={false} value={this.state.banquePrest} filter={true} filterBy='label' filterMatchMode='contains' options={this.state.liste_banque} onChange={(e) => {
                                    this.setState({ banquePrest: e.value });
                                }} appendTo={document.body} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">N° compte</label>
                                <InputText value={this.state.nocpteBanquePrest} onChange={(e) => this.setState({ nocpteBanquePrest: e.target.value })} />
                            </div>

                            <div className="col-12 md:col-2">
                                <label htmlFor="idPrest">Intitulé compte</label>
                                <InputText value={this.state.nomcpteBanquePrest} onChange={(e) => this.setState({ nomcpteBanquePrest: e.target.value })} />
                            </div>





                        </div>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index }, () => {
                            //
                        })}>
                            <TabPanel header="Indicateurs quantitatifs">
                                <DataTable value={this.state.liste_quantitatif} responsiveLayout='scroll'
                                    responsive={true} paginator={true} rows={100} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} selection={this.state.selectedParamEval} selectionMode="single">
                                    {/* <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} /> */}
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="idType.libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                    <Column field="puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix" sortable={true} style={{ width: '10%' }} editor={this.puPevalEditor} />
                                </DataTable>

                            </TabPanel>
                            <TabPanel header="Indicateurs qualitatifs">
                                <DataTable value={this.state.liste_qualitatif} responsiveLayout='scroll'
                                    responsive={true} paginator={true} rows={100} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} selection={this.state.selectedParamEval} selectionMode="single">
                                    {/* <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} /> */}
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="idType.libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                    <Column field="idType.idFamille.libCodif" filter={true} filterMatchMode='contains' header="Famille" sortable={true} style={{ width: '30%' }} />
                                    <Column field="maxPeval" filter={true} filterMatchMode='contains' header="Note max." sortable={true} style={{ width: '12%' }} editor={this.maxPevalEditor} />
                                </DataTable>
                            </TabPanel>
                            <TabPanel disabled={!this.state.modif} header="Modification affectation">
                                <DataTable value={this.state.liste_param_eval} responsiveLayout='scroll'
                                    responsive={true} paginator={true} rows={100} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    onSelectionChange={e => this.setState({ selectedParamEval: e.value })} selection={this.state.selectedParamEval} selectionMode="single">
                                    <Column header="#" body={this.actionTemplatePeval.bind(this)} style={{ width: '8%' }} />
                                    <Column field="idType.libType" filter={true} filterMatchMode='contains' header="Désignation" sortable={true} />
                                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                    <Column field="idType.categorieType" filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '12%' }} />
                                    <Column field="puPeval" body={(rowData, column) => {
                                        return <div>
                                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.puPeval))}</span>
                                        </div>
                                    }} filter={true} filterMatchMode='contains' header="Prix" sortable={true} style={{ width: '10%' }} />
                                    <Column field="maxPeval" filter={true} filterMatchMode='contains' header="Note max." sortable={true} style={{ width: '12%' }} />
                                </DataTable>
                            </TabPanel>
                        </TabView>



                    </div>

                </Dialog>


                <DataTable value={this.state.listeprest} paginatorPosition="top" selectionMode="single" paginator={true} rows={20} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedPrest: e.data })} onRowSelect={e => this.setState({ selectedPrest: e.data })}
                    responsive={true} selection={this.state.selectedPrest} ref={(el) => { this.dt = el; }} responsiveLayout='scroll'>
                    <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                    <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                    <Column field="nomPrest" filter={true} filterMatchMode='contains' header="Nom" sortable={true} />
                    <Column field="typePrest.libCodif" filter={true} filterMatchMode='contains' header="Type" sortable={true} style={{ width: '10%' }} />
                    {/* <Column field="categoriePrest.libCodif" filter={true} filterMatchMode='contains' header="Catégorie" sortable={true} style={{ width: '13%' }} /> */}
                    <Column field="idSite.prefectureSite" filter={true} filterMatchMode='contains' header="Zone" sortable={true} style={{ width: '12%' }} />
                    <Column field="nomPrestParent" filter={true} filterMatchMode='contains' header="Structure" sortable={true} style={{ width: '18%' }} />
                    {/* <Column field="idSite.nomSite" filter={true} filterMatchMode='contains' header="Site" sortable={true} style={{ width: '18%' }} /> */}
                    <Column field="bonusPrest" body={(rowData, column) => {
                        return <div>
                            <span>{this.FbrService.formaterValueSep(parseFloat(rowData.bonusPrest))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' header="Bonus" sortable={true} style={{ width: '8%' }} />

                    <Column field="ouId" filter={true} filterMatchMode='contains' header="OUID" sortable={true} style={{ width: '12%' }} />

                </DataTable>
            </div>

        );
    }
}